import react from 'react';
import work_first from '../images/work_img_1.jpg';
import work_second from '../images/work_img_2.jpg';
import work_third from '../images/work_img_3.jpg';
import work_four from '../images/work_img_4.jpg';

class Home extends react.Component {
    render() {
        return <div>
        <section className="tf__banner">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-8">
                <div className="tf__banner_text wow fadeInUp" data-wow-duration="1.5s">
                  <h1>Students for <span>Little</span> Education from.</h1>
                  <p>
                    Our agency can only be as strong as our people &amp; because of team
                    have designed game changing products.
                  </p>
                  <ul className="d-flex flex-wrap align-items-center">
                    <li><a className="common_btn" href="#">Read More</a></li>
                    <li>
                      <a className="venobox play_btn" data-autoplay="true" data-vbtype="video" href="#">
                        <i className="fas fa-play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        <section className="tf__categories mt_95">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-8 col-lg-6 m-auto wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__heading_area mb_15">
                  <h5>OUR COURSE CATEGORIES</h5>
                  <h2>We success for categories creative students.</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__single_category light_blue">
                  <div className="tf__single_category_icon">
                    <i className="fas fa-book" />
                  </div>
                  <div className="tf__single_category_text">
                    <h3>Music Conference</h3>
                    <p>We can provide you with a handyan in London.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__single_category blue">
                  <div className="tf__single_category_icon">
                    <i className="fas fa-book" />
                  </div>
                  <div className="tf__single_category_text">
                    <h3>Book Exclusive</h3>
                    <p>We can provide you with a handyan in London.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__single_category green">
                  <div className="tf__single_category_icon">
                    <i className="fas fa-book" />
                  </div>
                  <div className="tf__single_category_text">
                    <h3>School Study</h3>
                    <p>We can provide you with a handyan in London.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__single_category gray">
                  <div className="tf__single_category_icon">
                    <i className="fas fa-book" />
                  </div>
                  <div className="tf__single_category_text">
                    <h3>School Study</h3>
                    <p>We can provide you with a handyan in London.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__single_category orange">
                  <div className="tf__single_category_icon">
                    <i className="fas fa-book" />
                  </div>
                  <div className="tf__single_category_text">
                    <h3>Exclusive Party</h3>
                    <p>We can provide you with a handyan in London.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__single_category red">
                  <div className="tf__single_category_icon">
                    <i className="fas fa-book" />
                  </div>
                  <div className="tf__single_category_text">
                    <h3>Exclusive Man</h3>
                    <p>We can provide you with a handyan in London.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="tf__work pt_95">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-8 col-lg-6 m-auto wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__heading_area mb_35 md_margin">
                  <h5>OUR Working now</h5>
                  <h2>Complete About Students Advance Course.</h2>
                </div>
              </div>
            </div>
            <div className="row work_slider">
              <div className="col-xl-4">
                <div className="tf__work_single blue">
                  <div className="tf__work_single_img">
                    <img src={work_first} alt="work" className="img-fluid w-100" />
                  </div>
                  <div className="tf__work_single_text">
                    <h3>Drawing Powerful</h3>
                    <p>
                      We can provide you with a reliable handyan in London. you need
                      to included the today.
                    </p>
                    <a href="#"><i className="fas fa-arrow-right" /></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__work_single orange">
                  <div className="tf__work_single_img">
                    <img src={work_second} alt="work" className="img-fluid w-100" />
                  </div>
                  <div className="tf__work_single_text">
                    <h3>Classes Completed</h3>
                    <p>
                      We can provide you with a reliable handyan in London. you need
                      to included the today.
                    </p>
                    <a href="#"><i className="fas fa-arrow-right" /></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__work_single green">
                  <div className="tf__work_single_img">
                   <a> <img src={work_third} alt="work" className="img-fluid w-100" /></a>
                  </div>
                  <div className="tf__work_single_text">
                    <h3>Techniques Teens</h3>
                    <p>
                      We can provide you with a reliable handyan in London. you need
                      to included the today.
                    </p>
                    <a href="#"><i className="fas fa-arrow-right" /></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 wow fadeInUp" data-wow-duration="1.5s">
                <div className="tf__work_single red">
                  <div className="tf__work_single_img">
                    <img src={work_four} alt="work" className="img-fluid w-100" />
                  </div>
                  <div className="tf__work_single_text">
                    <h3>Classes Completed</h3>
                    <p>
                      We can provide you with a reliable handyan in London. you need
                      to included the today.
                    </p>
                    <a href="#"><i className="fas fa-arrow-right" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="tf__video mt_100">
          <div className="tf__video_overlay pt_100 pb_100">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 m-auto wow fadeInUp" data-wow-duration="1.5s">
                  <div className="tf__video_text">
                    <a className="venobox play_btn" data-autoplay="true" data-vbtype="video" href="#">
                      <i className="fas fa-play" />
                    </a>
                    <h4>Let’s best Something Agency</h4>
                    <p>
                      There are many variations of passages of agency Lorem Ipsum
                      Fasts injecte.
                    </p>
                    <a className="common_btn" href="#">Apply Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
   ;
    }
}

export default Home;