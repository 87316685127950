import logo from './images/logo.png';
import Forms from './Form.css';
import React, { Component } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";


const { useState, useEffect } = React;

const Modal = ({ onRequestClose, content }) => {
	
	// Use useEffect to add an event listener to the document
	useEffect(() => {
		function onKeyDown(event) {
			if (event.keyCode === 27) {
				// Close the modal when the Escape key is pressed
				onRequestClose();
			}
		}

		// Prevent scolling
		document.body.style.overflow = "hidden";
		document.addEventListener("keydown", onKeyDown);

		// Clear things up when unmounting this component
		return () => {
			document.body.style.overflow = "visible";
			document.removeEventListener("keydown", onKeyDown);
		};
	});

  
	return (
<div className="modal__backdrop">
<div className="modal__container">
  
  <h3 className="modal__title">I'm a  Yes modal!</h3>
  <p>
    {/* When this modal is open, we disable scrolling the This allows users to scroll the modal
    without losing their position on the page. */}
  {content}
  </p>
 
  <button type="button" onClick={onRequestClose}>
    Close this modal
  </button>
 
</div>
</div>
    
	);
};



// class App extends Component {

//   onChangeValue1(event) {

//     //console.log(event.target.value);
//     alert(event.target.value);

//   }

//   render() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <h1>Students for Little Education from.</h1> 
//         <p>Our agency can only be as strong as our people & because of team have designed game changing products.</p>
//       </header>
//       <form>
//       <div onChange={this.onChangeValue1}>

//         <input type="radio" value="Yes"  name="select" /> Yes
//         {/* <label className="option option-1"> */}
     
      
//       {/* </label> */}

//         <input type="radio" value="No"  name="select" /> No
//         {/* <label className="option option-2"> */}
     
      
//    {/* </label> */}

//       </div>
//       </form>
//     </div>
    

//   );
//   }  
//   }

export default Modal;


