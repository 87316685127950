// import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Bootstrap from './Bootstrap.css';
import Responsive from './Responsive.css';
import Slick from './Slick.css';
import FontAwesome from './All.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Verify from './pages/Verify';
import Expired from './pages/Expired';
function App() {
  return (
    <Router>  
      {window.location.pathname !== "/verify" && window.location.pathname !== 'verification/expired' ? (
          <Header />
) : null}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/verify" element={<Verify />} />
        <Route exact path="/verification/expired" element={<Expired />} />
      </Routes>
      {window.location.pathname !== "/verify" && window.location.pathname !== 'verification/expired' ? (
          <Footer />
) : null}
    </Router>
  );
}

export default App;
