import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from '../images/logo.png';
import school from '../images/logo-school.png';
import android from '../images/android.png';
import ios from '../images/ios.png';
import Modal from '../Modal';
import React, { useEffect, useState } from "react";

const Form = () => {	
	const navigate = useNavigate();
    const [isModalOpen, setModalIsOpen] = useState(false);
	const [isModalType, setModalIsType] = useState(false);
	const [hashValue, setHashValue] = useState(null);
	const [schoolName, setschoolName] = useState(null) 
	const [content, setContent] = useState();
	const [parentApprovedMessage, setParentApprovedMessage] = useState();
	const [parentApprovedStatus, setparentApprovedStatus] = useState(0);
	const [parentRequestStatus, setParentRequestStatus] = useState(0);
	useEffect(()=>{
		const params = new URLSearchParams(window.location.search);
		const hash = params.get("hash");
		axios({
			url: "https://api.evzone.app/api/v1.0/app/school/parent/details",
			method: "POST",
			data:  {
				"hash_id":hash,
			}
		}).then((res)=>{
			setHashValue(res.data.data.hash_id)
			setschoolName(res.data.data.school_name)
			setParentRequestStatus(res.status);
		}).catch((err)=>{
			let message = "Invalid hash id"
			navigate('/', { state: { message } });
		})
	},[]);
	const toggleModal = (event) => {
		axios({
			url: "https://api.evzone.app/api/v1.0/app/school/parent/approve",
			method: "POST",
			data:  {
				"hash_id":hashValue,
				"permission_type": event.target.value
			} ,
		}).then((res)=>{
			setparentApprovedStatus(res.status)
			setParentApprovedMessage(res.data.message)
		}).catch((err)=>{
			let message = err.response.data.message;
			navigate('/verification/expired', { state: { message } });
		})
	}
  	return (
		<>
		{parentRequestStatus === 200 && 
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<img src={school} className="App-logo-school" alt="logo" />
				<h1>{schoolName}</h1> 
				{parentApprovedStatus === 200 ? 
					<p>{parentApprovedMessage}</p>
					:
					<p>You are requested to provide approval so that we can proceed to register you as a parent to the above school.<br /><br />By approving, you'll be able to monitor your student's location, performance, attendance, and general academic behavior.
					</p> 
				}
			</header>
			{parentApprovedStatus !== 200 &&
			<button onClick={toggleModal} value="yes" type="button">Register Me</button>
			}
			{parentApprovedStatus !== 200 &&
			<button  value="no" className="cancel-btn" type="button" onClick={toggleModal} >I'm not a Parent</button>}
			<br />
			<p>To fully benefit from this, please download our school app </p><br />
			<button value="IOS" 
				onClick={(e) => {
					e.preventDefault();
					window.location.href='https://i.diawi.com/Ca8GjX';
				}}
				type="button"><img src={ios} className="App-logo-btn" alt="logo" /> IOS App
			</button> 
			<button value="Android" 
			onClick={(e) => {
				e.preventDefault();
				window.location.href='https://play.google.com/store/apps/details?id=com.evzoneride.user';
			}}
			type="button"><img src={android} className="App-logo-btn" alt="logo" /> Android App
			</button>
		</div>
		}
		</>
  	)
}

export default Form