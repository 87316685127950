import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Expired = (props) => {
    const location = useLocation();
    const message = location.state?.message || '';

    return (
        <>
        <Header />
        <div>
           {message}
        </div>
        <Footer />
        </>
    )
}

export default Expired