import react from 'react';
import footer_logo from '../images/footer-logo.png';
import footer_bg from '../images/footer_bg.jpg';

class Footer extends react.Component{
    render(){
        return (
            <footer className="tf__footer mt_100">
            <div className="tf__footer_overlay pt_75">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-xl-3 col-sm-10 col-md-7 col-lg-6">
                    <div className="tf__footer_logo_area">
                      <a className="footer_logo" href="index.html">
                        <img src={footer_logo} alt="Eduor" className="img-fluid w-100" />
                      </a>
                      <p>
                        Nemo enim ipsam voluptate quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia magni this dolores eos qui ratione .
                      </p>
                      <ul className="d-flex flex-wrap">
                        <li>
                          <a href="#"><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li>
                          <a href="#"><i className="fab fa-linkedin-in" /></a>
                        </li>
                        <li>
                          <a href="#"><i className="fab fa-twitter" /></a>
                        </li>
                        <li>
                          <a href="#"><i className="fab fa-pinterest-p" /></a>
                        </li>
                        <li>
                          <a href="#"><i className="fab fa-google-plus-g" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-2 col-sm-10 col-md-5 col-lg-5">
                    <div className="tf__footer_content xs_mt_50">
                      <h3>Quick Links</h3>
                      <ul>
                        <li><a href="#">Link01</a></li>
                        <li><a href="#">Link02</a></li>
                        <li><a href="#">Link03</a></li>
                        <li><a href="#">Link04</a></li>
                        <li><a href="#">Link05</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-10 col-md-7 col-lg-col-lg-6">
                    <div className="tf__footer_content xs_mt_30">
                      <h3>Our Contacts</h3>
                      <p>Adress: 27 Division St, Berakuti, NY 121102, Cameroon</p>
                      <p>
                        <span> Phone: +8 1440 456 782</span>
                        <span>Fax: +8 846512 456 788</span>
                      </p>
                      <p>
                        <span>Email: example@mail.com </span>
                        <span>Website: yourwebsite.com</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-10 col-md-5 col-lg-4 col-lg-5">
                    <div className="tf__footer_content xs_mt_45">
                      <h3>News Letter</h3>
                      <p>
                        Our approach to itis unique around know work an we know Get
                        hands on the you like
                      </p>
                      <form>
                        <input type="text" placeholder="Your Email" />
                        <button>send</button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="tf__copyright">
                      <p>Copyright ©Zone all rights reserved.</p>
                      <ul className="d-flex flex-wrap">
                        <li><a href="#">Privacy policy</a></li>
                        <li><a href="#">About</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tf__scroll_btn"> go to top </div>
            
          </footer>

        );
    }
}

export default Footer;