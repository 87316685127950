import react from 'react';
import logo from '../images/logo.png';

class Header extends react.Component{
    render(){
        return (
            <nav className="navbar navbar-expand-lg main_menu">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={logo} alt="School Admin" className="img-fluid w-100" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fas fa-bars menu_icon" />
            <i className="fas fa-times close_icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link common_btn" href="https://schoolpanel.evzone.app/login">Login</a>
              </li>
              <li className="nav-item">
                <a className="nav-link common_btn" href="https://schoolpanel.evzone.app/signup">Sign-up</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
        );
    }
}



export default Header;